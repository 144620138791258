<template>
  <el-row class="contact-wrapper">
    <el-col :span="10" :offset="7">
      <h3 class="h3">{{ $t("L_ABOUT.CONTENT_0005") }} </h3>
      <p class="p1">{{ $t("L_ABOUT.CONTENT_0006") }}</p>
      <p class="p1"> {{ $t("L_ABOUT.CONTENT_0007") }}</p>
      <p class="p1"> {{ $t("L_ABOUT.CONTENT_0008") }}</p>
      <p class="p1"> {{ $t("L_ABOUT.CONTENT_0010") }}</p>
      <div class="p1 p3">
        <span>{{ $t("L_ABOUT.CONTENT_0011") }}</span>
        <p class="p2">
          <img src="../../../assets/image/pc/aboutus/corporationcode.jpg" alt=""/>
        </p>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "contactus"
}
</script>

<style scoped>

</style>